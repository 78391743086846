import { useState, useEffect } from 'react';
import { useAuth } from '../auth/AuthContext';

const Dashboard = () => {
  const [healthStatus, setHealthStatus] = useState('Checking...');
  const [error, setError] = useState('');
  const { user, logout } = useAuth();

  const apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3001';

  useEffect(() => {
    const fetchHealthStatus = async () => {
      try {
        const response = await fetch(`${apiUrl}/health`);

        if (!response.ok) {
          throw new Error(`Server responded with status: ${response.status}`);
        }

        const data = await response.json();
        setHealthStatus(`${data.status}: ${data.message}`);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
        setError(`Error connecting to the backend: ${errorMessage}`);
        console.error(err);
      }
    };

    fetchHealthStatus();
  }, [apiUrl]);

  return (
    <div className="dashboard">
      <header>
        <h1>Personal Hub</h1>
        <div className="user-info">
          {user?.email && <span>Welcome, {user.email}</span>}
          <button onClick={logout} className="logout-button">
            Logout
          </button>
        </div>
      </header>

      <div className="card">
        <h2>Backend Status</h2>
        {error ? <p className="error">{error}</p> : <p>{healthStatus}</p>}
        <p className="info">Using API at: {apiUrl}</p>
      </div>
    </div>
  );
};

export default Dashboard;
